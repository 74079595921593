import React, { useContext } from 'react';
import { Flex } from './Flex';
import { LanguageContext } from '../layout/Layout';
import { Hover } from './Hover';
import { Paragraph } from './Paragraph';
import { useStaticQuery, Link, graphql } from 'gatsby';
import styled from 'styled-components';

const q = graphql`
  query NextPrevNewsletterQuery {
    news: sanityNewsletterSettings {
      posts {
        _id
        title
        slug {
          current
        }
      }
    }
  }
`;

Number.prototype.mod = function(n) {
  return ((this % n) + n) % n;
};

const Spacer = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export const NextPrevBlog = ({ current = '' }) => {
  const lang = useContext(LanguageContext);

  const data = useStaticQuery(q);
  const posts = data.news.posts;
  const caseIds = posts.map((c) => c._id);
  const currentIndex = caseIds.indexOf(current);

  const prevIndex = (currentIndex - 1).mod(posts.length);
  const nextIndex = (currentIndex + 1).mod(posts.length);

  const prevCaseTitle = posts[prevIndex].title;
  const prevCaseSlug = posts[prevIndex].slug.current;
  const nextCaseTitle = posts[nextIndex].title;
  const nextCaseSlug = posts[nextIndex].slug.current;

  const prevText = lang === `nb` ? 'Forrige' : 'Previous';
  const nextText = lang === `nb` ? 'Neste' : 'Next';

  return (
    <Flex wrap justify="space-between" style={{ width: '100%' }}>
      <CaseLink heading={prevCaseTitle} subtitle={prevText} slug={prevCaseSlug} back />
      <Spacer />
      <CaseLink
        heading={nextCaseTitle}
        subtitle={nextText}
        slug={nextCaseSlug}
        next
        style={{ marginLeft: 'auto' }}
        styleInner={{ alignItems: 'flex-end' }}
      />
    </Flex>
  );
};

const CaseLink = ({ heading, subtitle, slug, styleInner, style, next, back }) => {
  const lang = useContext(LanguageContext);
  const url = `/newsletter/${slug}`;

  return (
    <Link to={url} style={style} state={{ goingNext: next, goingBack: back }}>
      <Hover>
        <Flex column style={{ ...styleInner }}>
          <Paragraph size="label" weight="normal" style={{ margin: 0 }}>
            {subtitle}
          </Paragraph>
          <Paragraph size="ingress" weight="bold" style={{ margin: 0 }}>
            {heading}
          </Paragraph>
        </Flex>
      </Hover>
    </Link>
  );
};
