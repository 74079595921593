import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { usePageTitle } from '../hooks/usePageTitle';
import { BlockContent } from '../components/block-content';
import HeaderPadding from '../layout/HeaderPadding';
import { LanguageContext } from '../layout/Layout';
import { NextPrevBlog } from '../components/NextPrevBlog';
import { Flex } from '../components/Flex';
import SEO from '../components/seo';
import useOGImage from '@hooks/useOGImage';
import useGetBlock from '@hooks/useGetBlock';

export const query = graphql`
  query NewsletterTemplateQuery($id: String!) {
    newsletter: sanityNewsletter(id: { eq: $id }) {
      _id
      title
      image {
        asset {
          url
        }
      }
      body: _rawBody
    }
  }
`;

const Newsletter = ({ data: { newsletter: post } }) => {
  const title = post.title;

  usePageTitle(title);

  const ogImage = useOGImage(post.image);
  const description = useGetBlock(post.body);

  return (
    <React.Fragment>
      <SEO {...ogImage} description={description} title={title} />
      <HeaderPadding />
      <BlockContent key={post._id} blocks={post.body} />
      <HeaderPadding />
      <NextPrevBlog current={post._id} />
    </React.Fragment>
  );
};

export default Newsletter;
